import store from "@/store";

export default [
	{
		path: '/acoes-realizadas',
		name: 'acoes-realizadas.index',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/acoes-realizadas/index"),
		meta: {
			title: 'Ações Realizadas'
		}
	},
	{
		path: '/acoes-realizadas/criar',
		name: 'acoes-realizadas.create',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/acoes-realizadas/edit"),
		meta: {
			title: 'Criar Ação'
		}
	},
	{
		path: '/acoes-realizadas/:id',
		name: 'acoes-realizadas.edit',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/acoes-realizadas/edit"),
		meta: {
			title: 'Editar Ação'
		}
	}
];
