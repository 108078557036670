import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from '@/store';
import Auditorias from './routes/auditorias';
import Clientes from './routes/clientes';
import Perfil from './routes/perfil';
import Pacotes from './routes/pacotes';
import Questionarios from './routes/questionarios';
import Relatorios from './routes/relatorios';
import Usuarios from './routes/usuarios';
import Page404 from './routes/404';
import AcoesRealizadas from './routes/acoes-realizadas';

const routes = [
	{
		path: '',
		name: 'dashboard.index',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import ('../views/dashboard/index.vue'),
		meta: {
			title: 'Dashboard'
		}
	},
	{
		path: '/login',
		name: 'login.index',
		component: () => import ('../views/login/index.vue'),
		meta: {
			title: 'Login'
		}
	},
	{
		path: '/redefinir-senha',
		name: 'redefinir-senha',
		component: () => import ('../views/redefinir-senha/index.vue'),
		meta: {
			title: 'Redefinição de Senha'
		}
	},
	...AcoesRealizadas,
	...Auditorias,
	...Clientes,
	...Perfil,
	...Pacotes,
	...Questionarios,
	...Relatorios,
	...Usuarios,
	...Page404,
]

export default createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})
