import {toastController} from "@ionic/core";
import moment from "moment";
import {alertController} from "@ionic/vue";
import store from "@/store";

const _ = require('lodash');

const reactivePush = function(root, path, value) {
	let newRoot = root;
	let pathValue = _.get(newRoot, path);
	pathValue.push(value);

	root = Object.assign({}, root, newRoot);
};

const reactiveSet = function(root, path, value) {
	let newRoot = root;
	root = _.set(newRoot, path, value);
};

const reactiveDelete = function(root, path, index) {
	let newRoot = root;

	_.unset(newRoot, `${path}[${index}]`);

	let arr = _.get(newRoot, path);
	arr.splice(index, 1);

	root = newRoot;
};

const possiveisRespostas = [
	{
		name: "C",
		size: "default",
		color: {
			default: "primary",
			selected: "success"
		}
	},
	{
		name: "NC",
		size: "default",
		color: {
			default: "primary",
			selected: "danger"
		}
	},
	{
		name: "PC",
		size: "default",
		color: {
			default: "primary",
			selected: "warning"
		}
	},
	{
		name: "NA",
		size: "default",
		color: {
			default: "primary",
			selected: "secondary"
		}
	},
	{
		name: "OM",
		size: "default",
		color: {
			default: "primary",
			selected: "tertiary"
		}
	}
];

const labelsPossiveisRespostas = possiveisRespostas.reduce(function(ac, p) {
	ac.push(p.name);
	return ac;
}, []);

const colorsPossiveisRespostas = possiveisRespostas.reduce(function(ac, p){
	ac.push(`var(--ion-color-${p.color.selected})`);
	return ac;
}, [])

const dateToString = (date, format = 'DD/MM/YYYY HH:mm:ss') => moment(date).format(format);

/**
 * Exibe toast.
 *
 * @param message
 * @param duration
 * @returns {Promise<void>}
 */
const toast = async function(message, duration = 2000) {
	console.log(message);

	const toast = await toastController
		.create({
			message: message,
			duration: duration
		})
	return toast.present();
};

const confirmAlert = async function(msg, yesHandler, cancelHandler = () => {}) {
	const alert = await alertController
		.create({
			header: 'Confirmação',
			message: msg,
			buttons: [
				{
					text: 'Cancelar',
					role: 'cancel',
					cssClass: 'secondary',
					handler: cancelHandler,
				},
				{
					text: 'Sim',
					handler: yesHandler,
				},
			],
		});
	return alert.present();
};

const redirectToLogin = function() {
	window.location = '/login';
};

const whatsAppLink = (text, phone = '') => {
	return `https://wa.me/${phone}?text=${encodeURI(text)}`;
}

const openToast = async function (message, duration = 2000) {
	console.debug(message);

	const toast = await toastController
		.create({
			message: message,
			duration: duration
		})
	return toast.present();
};

const presentAlert = async function (title, html) {
	const alert = await alertController
		.create({
			header: title,
			message: html,
			buttons: [
				{
					text: 'Ok',
					handler: () => {
						console.log('Confirm Okay')
					},
				},
			],
		});
	return alert.present();
}

const break_line_to_br = function (str) {
	return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
};

const exibeErro = async function (error) {
	console.error(error);

	if (Object.prototype.hasOwnProperty.call(error, 'response')) {
		// if (_.get(error, 'constructor.name') === "Error") {
		const message = _.get(error.response.data, 'message');
		const errors = _.get(error.response.data, 'errors', []);

		//Para quando retorna 403 sem uma mensagem
		if (error.response.status === 403/* && (message.trim() === "" || !message)*/) {
			await presentAlert('Oops...', 'Proibido!');

			//Para quando retorna 401 sem uma mensagem
		} else if (error.response.status === 401/* && (message.trim() === "" || !message)*/) {
			await openToast('Não autenticado! Você está sendo redirecionado para o login...');
			await store.dispatch(`logout`);

		} else {
			await presentAlert('Oops...', Object.entries(errors).reduce(
				function (ac, error) {
					return ac += `<span style="text-decoration: underline">${error[0]}</span>:<br> ${error[1].reduce(
						(ac2, item) => ac2 += `* ${item}<br>`,
						""
					)}`;
				},
				`<strong>${break_line_to_br(message)}</strong><br>`
			));
		}

	} else {
		openToast(error)
	}
};

export {
	reactivePush,
	reactiveSet,
	reactiveDelete,
	toast,
	dateToString,
	confirmAlert,
	redirectToLogin,
	possiveisRespostas,
	labelsPossiveisRespostas,
	colorsPossiveisRespostas,
	whatsAppLink,
	exibeErro
};
