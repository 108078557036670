import store from "@/store";

export default [
	{
		path: '/usuarios',
		name: 'usuarios.index',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/usuarios/index"),
		meta: {
			title: 'Usuários'
		}
	},
	{
		path: '/usuarios/criar',
		name: 'usuarios.create',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/usuarios/edit"),
		meta: {
			title: 'Criação Usuário'
		}
	},
	{
		path: '/usuarios/:id',
		name: 'usuarios.edit',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/usuarios/edit"),
		meta: {
			title: 'Edição Usuário'
		}
	},
];
