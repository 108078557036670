import store from "@/store";

export default [
	{
		path: '/clientes',
		name: 'clientes.index',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/clientes/index"),
		meta: {
			title: 'Clientes'
		}
	},
	{
		path: '/clientes/criar',
		name: 'clientes.create',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/clientes/edit"),
		meta: {
			title: 'Criação Cliente'
		}
	},
	{
		path: '/clientes/:id',
		name: 'clientes.edit',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/clientes/edit"),
		meta: {
			title: 'Edição Cliente'
		}
	}
];
