import store from "@/store";

export default [
	{
		path: '/auditorias',
		name: 'auditorias.index',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/auditorias/index"),
		meta: {
			title: 'Auditorias'
		}
	},
	// {
	// 	path: '/auditorias/criar',
	// 	name: 'auditorias.create',
	// 	beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
	// 	component: () => import("../../views/auditorias/edit"),
	// 	meta: {
	// 		title: 'Criação Auditoria'
	// 	}
	// },
	{
		path: '/auditorias/:id',
		name: 'auditorias.edit',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import('../../views/auditorias/edit'),
		children: [
			{
				path: '',
				redirect:  { name: 'auditorias.edit.dados.edit' }
			},
			{
				path: 'dados',
				name: 'auditorias.edit.dados.edit',
				component: () => import('../../views/auditorias/Dados.vue'),
				meta: {
					title: 'Cadastro da Auditoria'
				}
			},
			{
				path: 'conjuntos',
				name: 'auditorias.edit.conjuntos.edit',
				component: () => import('../../views/auditorias/ConjuntoRespostas'),
				meta: {
					title: 'Conjunto de Respostas'
				}
			},
		]
	},

	//Não coloquei alí em cima em children porque não quero que aparece as Tabs nessa página
	{
		path: '/auditorias/:id/conjuntos/:set',
		name: 'auditorias.edit.conjuntos.edit.respostas.edit',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import('../../views/auditorias/respostas/edit'),
		meta: {
			title: 'Respostas do Conjunto'
		}
	},
	{
		path: '/auditorias/:id/conjuntos/criar',
		name: 'auditorias.edit.conjuntos.edit.respostas.create',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import('../../views/auditorias/respostas/edit'),
		meta: {
			title: 'Respostas do Conjunto'
		}
	},
];
