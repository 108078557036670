export default [
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		component: () => import("../../views/404/index"),
		meta: {
			title: "Não Encontrado (404)"
		}
	},
	{
		path: '/404',
		name: '404',
		component: () => import("../../views/404/index"),
		meta: {
			title: "Não Encontrado (404)"
		}
	}
];
