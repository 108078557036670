import store from "@/store";

export default [
	{
		path: '/questionarios',
		name: 'questionarios.index',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/questionarios/index"),
		meta: {
			title: 'Questionários'
		}
	},
	{
		path: '/questionarios/criar',
		name: 'questionarios.create',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/questionarios/edit"),
		meta: {
			title: 'Criação Questionário'
		}
	},
	{
		path: '/questionarios/:id',
		name: 'questionarios.edit',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/questionarios/edit"),
		meta: {
			title: 'Edição Questionário'
		}
	}
];
