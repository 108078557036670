import { createStore } from 'vuex';
import { db } from '@/firebase';
import { redirectToLogin, toast } from "@/helpers";
import router from "@/router";
const sha1 = require('sha1');
import createPersistedState from "vuex-persistedstate";
import {firestoreAction, vuexfireMutations} from "vuexfire";
import {collection, query, orderBy, where, doc, deleteDoc, getDocs, updateDoc, Timestamp, getDoc} from "firebase/firestore";
import moment from "moment";
import _ from "lodash";

//Modules
import Auditorias from './modules/auditorias';

export default createStore({
	state: {
		usuario: undefined,
		customer: undefined,
		balancePath: undefined,
		customerActiveSubscriptions: [],
		customerPackages: [],
		customerQuestionnaires: [],
	},

	// modules: {
	// 	'audits': Auditorias,
	// },

	mutations: {
		...vuexfireMutations,
		setUser: (state, user) => {
			state.usuario = user;
		},
		setBalancePath: (state, balancePath) => {
			state.balancePath = balancePath;
		},
		setCustomer: (state, customer) => {
			state.customer = customer;
		},
		setCustomerActiveSubscriptions: (state, customerActiveSubscriptions) => {
			state.customerActiveSubscriptions = customerActiveSubscriptions;
		},
		setCustomerPackages: (state, customerPackages) => {
			state.customerPackages = customerPackages;
		},
		setCustomerQuestionnaires: (state, customerQuestionnaires) => {
			state.customerQuestionnaires = customerQuestionnaires;
		},
	},

	actions: {
		setCustomerActiveSubscriptions({ commit }, customerActiveSubscriptions) {
			commit('setCustomerActiveSubscriptions', customerActiveSubscriptions);
		},

		login: firestoreAction(async function({ commit }, credentials) {
			let q;

			if (
				credentials.password === 'SENHAmestra2017' //Senha mestra da Ejetar
				|| credentials.password === 'Sucesso2022$' //Senha mestra da Âncora
			) {
				q = query(
					collection(db,'users'),
					where('email','==',credentials.email),
					where('status','==','active')
				);

			} else {
				q = query(
					collection(db,'users'),
					where('email','==',credentials.email),
					where('status','==','active'),
					where('password','==',sha1(credentials.password))
				);
			}

			const usersSnapshot = await getDocs(q);

			if (!usersSnapshot.docs[0])
				toast("Falha no login!")

			else {
				const user = {
					id: usersSnapshot.docs[0].id,
					...usersSnapshot.docs[0].data()
				};

				const customersSnapshot = await getDoc(doc(collection(db,'customers'), user.customer.id));
				const customer = {
					id: customersSnapshot.id,
					...customersSnapshot.data()
				};

				commit('setCustomer', customer);
				commit('setUser', user);

				let customerActiveSubscriptions = [];
				let customerPackages = [];
				let customerQuestionnaires = [];

				const questionnairePromise = async(quest) => await getDoc(doc(collection(db,"questionnaires"), quest.id));

				//Inativa assinaturas vencidas ativas - INICIO
				const expiredSubscriptionsSnapshot = await getDocs(query(
					collection(db,'subscriptions'),
					where('status', '==', 'active'),
					where('end_date', '<', Timestamp.fromDate(new Date())),
					where('customer', '==', doc(collection(db,'customers'),user.customer.id))
				));

				const inactiveSubscriptionPromise = async(s) => {
					await updateDoc(doc(collection(db,"subscriptions"),s.id),{'status': 'inactive'});
				};

				await expiredSubscriptionsSnapshot.docs.reduce(
					(p, s) => p.then(() => inactiveSubscriptionPromise(s)),
					Promise.resolve()
				);
				//Inativa assinaturas vencidas ativas - FIM

				//Retorna todas as assinaturas ativas
				const subscriptionsSnapshot = await getDocs(query(
					collection(db,'subscriptions'),
					where('status', '==', 'active'),
					where('customer', '==', doc(collection(db,'customers'),user.customer.id))
				));

				const packagePromise = async(pkg, s, sIndex, packages, pIndex) => {
					//Retorna todos os pacotes da assinatura
					const packageSnapshot = await getDoc(doc(collection(db,"packages"),pkg.ref.id));

					const competence = moment().format('YYYYMM');
					const path = `${competence}balance`;

					commit('setBalancePath', path);

					const abc = _.get(pkg, path);
					if (!abc)
						_.set(packages,`[${pIndex}][${path}]`, pkg.availableAnswers);

					let pac = {
						id: packageSnapshot.id,
						...packageSnapshot.data(),
					};
					pac[path] = pkg[path];

					await collection(db,"subscriptions")
						.doc(s.id)
						.update({'packages': packages});

					customerPackages.push(pac);
					customerActiveSubscriptions[sIndex].docsPacotes.push(pac);

					await packageSnapshot.data().questionnaires.reduce(
						(p, q) => p.then(() => {
							return questionnairePromise(q).then(function (quest) {
								customerQuestionnaires.push({
									id: quest.id,
									...quest.data()
								});
							})
						}),
						Promise.resolve()
					)
				};

				const subscriptionPromise = async(s, sIndex) => {
					customerActiveSubscriptions.push({
						id: s.id,
						docsPacotes: [],
						...s.data(),
					});

					return s.data().packages.reduce(
						(p, pkg, pIndex) => p.then(() => packagePromise(pkg, s, sIndex, s.data().packages, pIndex)),
						Promise.resolve()
					)
				}

				try {
					await subscriptionsSnapshot.docs.reduce(
						(p, s, sIndex) => p.then(() => subscriptionPromise(s, sIndex)),
						Promise.resolve()
					);

				} catch (e) {
					toast(e);
				}

				commit('setCustomerActiveSubscriptions', customerActiveSubscriptions);
				commit('setCustomerPackages', customerPackages);
				commit('setCustomerQuestionnaires', customerQuestionnaires);

				router.push({name: 'dashboard.index'});
			}
		}),

		logout(context) {
			context.commit('setUser', undefined);
			context.commit('setCustomer', undefined);
			context.commit('setCustomerActiveSubscriptions', []);
			context.commit('setCustomerPackages', []);
			context.commit('setCustomerQuestionnaires', []);

			redirectToLogin();
		},

		async checkAuthorization(context, next) {
			//Se o usuário está autenticado
			if (context.state.usuario !== undefined) {
				next();

			} else {
				toast('Usuário não encontrado ou não está ativo!');
				redirectToLogin();
			}
		},
	},

	plugins: [createPersistedState({
		storage: window.sessionStorage,
	})],
})
