import store from "@/store";

export default [
	{
		path: '/perfil',
		name: 'perfil.index',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/perfil/edit"),
		meta: {
			title: 'Perfil'
		}
	}
];
