import store from "@/store";

export default [
	{
		path: '/pacotes',
		name: 'pacotes.index',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/pacotes/index"),
		meta: {
			title: 'Pacotes'
		}
	},
	{
		path: '/pacotes/criar',
		name: 'pacotes.create',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/pacotes/edit"),
		meta: {
			title: 'Criação Pacote'
		}
	},
	{
		path: '/pacotes/:id',
		name: 'pacotes.edit',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/pacotes/edit"),
		meta: {
			title: 'Edição Pacote'
		}
	}
];
