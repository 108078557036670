import store from "@/store";

export default [
	{
		path: '/r',
		name: 'relatorios.index',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/relatorios/index"),
		meta: {
			title: 'Relatórios'
		}
	},
	// {
	// 	path: '/relatorios',
	// 	name: 'relatorios.index',
	// 	beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
	// 	component: () => import("../../views/relatorios/auditorias/pizza/view"),
	// 	meta: {
	// 		title: 'Relatórios'
	// 	}
	// },
	{
		path: '/r/auditorias/serie-historica',
		name: 'relatorios.auditorias.serie-historica.view',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/relatorios/auditorias/serie-historica/view"),
		meta: {
			title: 'Relatório Série Histórica'
		}
	},
	{
		path: '/r/auditorias/usuarios',
		name: 'relatorios.auditorias.usuarios.view',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/relatorios/auditorias/usuarios/view"),
		meta: {
			title: 'Relatório Auditorias por Auditor'
		}
	},
	{
		path: '/r/acoes-realizadas',
		name: 'relatorios.acoes-realizadas.view',
		beforeEnter: (to, from, next) => store.dispatch('checkAuthorization', next),
		component: () => import("../../views/relatorios/acoes-realizadas/view"),
		meta: {
			title: 'Relatório Ações Realizadas'
		}
	}
];
